<template>
    <div id="dashboard">
        <a-page-header style="border-bottom: 2px solid rgb(235, 237, 240); margin-bottom: 10px;" title="首页" sub-title="" />
        <div style="padding: 10px;">
            <a-card title="待处理" :hoverable="true">
                <a-row>
                    <a-col span="8">
                        <a-statistic title="待处理投诉与举报" :value="waiting_complaint + waiting_accusation" style="margin-right: 50px">
                            <template #suffix>
                                件
                            </template>
                        </a-statistic>
                    </a-col>
                    <a-col span="8">
                        <a-statistic title="待处理投诉" :value="waiting_complaint" style="margin-right: 50px">
                            <template #suffix>
                                件
                            </template>
                        </a-statistic>
                    </a-col>
                    <a-col span="8">
                        <a-statistic title="待处理举报" :value="waiting_accusation" style="margin-right: 50px">
                            <template #suffix>
                                件
                            </template>
                        </a-statistic>
                    </a-col>
                </a-row>
            </a-card>
        </div>
        <div>
            <a-divider v-if="$store.state.userInfo.userInfo.permission == 2" />
            <a-radio-group v-model:value="region" @change="draw_graph" v-if="$store.state.userInfo.userInfo.permission == 2">
                <a-radio-button :value="item.value" v-for="item in category" :key="item.value">{{item.text}}</a-radio-button>
            </a-radio-group>
            <a-divider />
        </div>
        <a-row style="margin-top: 10px;">
            <a-col span="24" style="padding: 10px; min-height: 300px;">
                <a-card title="过去一周数据统计" :hoverable="true">
                    <div ref="past_1w_chart_container" style="height: 400px;"></div>
                </a-card>
            </a-col>
        </a-row>
        <a-row>
            <a-col span="24" style="padding: 10px; min-height: 300px;">
                <a-card title="过去一月数据统计" :hoverable="true">
                    <div ref="past_1m_chart_container" style="height: 400px;"></div>
                </a-card>
            </a-col>
        </a-row>
        <a-row>
            <a-col span="24" style="padding: 10px; min-height: 300px;">
                <a-card title="过去一年数据统计" :hoverable="true">
                    <div ref="past_1y_chart_container" style="height: 400px;"></div>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
import moment from "moment";
import * as echarts from "echarts";
export default {
    methods: {
        
    },
    data () {
        return {
            
        };
    },
    setup() {
        const store = useStore();

        const waiting_complaint = ref(10);
        const waiting_accusation = ref(3);
        const past_1w_complaint = ref([3, 2, 3, 4, 5, 2, 6]);
        const past_1w_complaint_repeat = ref([1, 2, 2, 3, 1, 1, 1]);
        const past_1w_complaint_rating = ref([3, 2, 3, 2, 3, 2, 3]);
        const past_1w_accusation = ref([2, 3, 4, 2, 3, 4, 3]);
        const past_1w_accusation_repeat = ref([0, 1, 3, 1, 2, 3, 2]);
        const past_1w_accusation_rating = ref([2, 3, 2, 3, 2, 3, 2]);
        const past_1w_x = ref(['8-2', '8-3', '8-4', '8-5', '8-6', '8-7', '8-8']);
        const past_1m_complaint = ref([30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50]);
        const past_1m_complaint_repeat = ref([10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20]);
        const past_1m_complaint_rating = ref([2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3]);
        const past_1m_accusation = ref([40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30, 40, 50, 30]);
        const past_1m_accusation_repeat = ref([20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10, 20, 10]);
        const past_1m_accusation_rating = ref([3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2]);
        const past_1m_x = ref(['7-10', '7-11', '7-12', '7-13', '7-14', '7-15', '7-16', '7-17', '7-18', '7-19',
         '7-20', '7-21', '7-22', '7-23', '7-24', '7-25', '7-26', '7-27', '7-28', '7-29', '7-30', '7-31','8-1','8-2', '8-3', '8-4', '8-5', '8-6', '8-7', '8-8']);
        const past_1y_complaint = ref([3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5]);
        const past_1y_complaint_repeat = ref([1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2]);
        const past_1y_complaint_rating = ref([2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3]);
        const past_1y_accusation = ref([4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3]);
        const past_1y_accusation_repeat = ref([2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1]);
        const past_1y_accusation_rating = ref([3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2]);
        const past_1y_x = ref(['2021-9', '2021-10', '2021-11', '2021-12', '2022-1', '2022-2', '2022-3', '2022-4', '2022-5', '2022-6', '2022-7', '2022-8']);

        const region = ref(0); // 显示类别

        const category = computed(() => {
            let result = [];
            for (let i in store.state.category.category) {
                if (i && store.state.category.category[i] != '') {
                    result.push({ text: store.state.category.category[i], value: i });
                }
            }
            return result;
        });

        const past_1w_chart_container = ref(null);
        const past_1m_chart_container = ref(null);
        const past_1y_chart_container = ref(null);
        const past_1w_chart = ref(null);
        const past_1m_chart = ref(null);
        const past_1y_chart = ref(null);

        function draw_graph() {
            var graph_option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#999"
                        }
                    }
                },
                toolbox: {
                    feature: {
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ["line", "bar"] },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                xAxis: [
                    {
                        type: "category",
                        axisPointer: {
                            type: "shadow"
                        }
                    }
                ],
                yAxis: [
                    {
                        type: "value",
                        name: '投诉/举报件数',
                        axisLabel: {
                            formatter: "{value} 件"
                        }
                    },
                    {
                        type: "value",
                        name: '投诉/举报评分',
                        axisLabel: {
                            formatter: "{value} 分"
                        },
                        min: 0,
                        max: 5
                    }
                ]
            };
            if (!past_1w_chart.value) {
                past_1w_chart.value = echarts.init(past_1w_chart_container.value);
            }
            if (!past_1m_chart.value) {
                past_1m_chart.value = echarts.init(past_1m_chart_container.value);
            }
            if (!past_1y_chart.value) {
                past_1y_chart.value = echarts.init(past_1y_chart_container.value);
            }
            past_1w_chart.value.setOption(graph_option);
            past_1m_chart.value.setOption(graph_option);
            past_1y_chart.value.setOption(graph_option);
            past_1w_chart.value.setOption({
                xAxis: {
                    data: past_1w_x.value
                },
                series: [
                    {
                        name: '投诉',
                        type: 'bar',
                        data: past_1w_complaint.value[region.value],
                        stack: 'complaint',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        name: '投诉复议',
                        type: 'bar',
                        data: past_1w_complaint_repeat.value[region.value],
                        stack: 'complaint',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        name: '举报',
                        type: 'bar',
                        data: past_1w_accusation.value[region.value],
                        stack: 'accusation',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        name: '举报复议',
                        type: 'bar',
                        data: past_1w_accusation_repeat.value[region.value],
                        stack: 'accusation',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        name: '投诉评分',
                        type: 'line',
                        data: past_1w_complaint_rating.value[region.value],
                        yAxisIndex: 1,
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        markPoint: {
                            data: [
                                { type: "max", name: "最大值" },
                                { type: "min", name: "最小值" }
                            ]
                        },
                        markLine: {
                            data: [
                                { type: "average", name: "平均值" }
                            ]
                        }
                    },
                    {
                        name: '举报评分',
                        type: 'line',
                        data: past_1w_accusation_rating.value[region.value],
                        yAxisIndex: 1,
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        markPoint: {
                            data: [
                                { type: "max", name: "最大值" },
                                { type: "min", name: "最小值" }
                            ]
                        },
                        markLine: {
                            data: [
                                { type: "average", name: "平均值" }
                            ]
                        }
                    }
                ],
                legend: {
                    // data: ['投诉', '投诉复议', '举报', '举报复议', '投诉评分', '举报评分'],
                    data: ['投诉', '举报'],
                }
            });
            past_1m_chart.value.setOption({
                xAxis: {
                    data: past_1m_x.value
                },
                series: [
                    {
                        name: '投诉',
                        type: 'bar',
                        data: past_1m_complaint.value[region.value],
                        stack: 'complaint',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        name: '投诉复议',
                        type: 'bar',
                        data: past_1m_complaint_repeat.value[region.value],
                        stack: 'complaint',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        name: '举报',
                        type: 'bar',
                        data: past_1m_accusation.value[region.value],
                        stack: 'accusation',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        name: '举报复议',
                        type: 'bar',
                        data: past_1m_accusation_repeat.value[region.value],
                        stack: 'accusation',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        name: '投诉评分',
                        type: 'line',
                        data: past_1m_complaint_rating.value[region.value],
                        yAxisIndex: 1,
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        markPoint: {
                            data: [
                                { type: "max", name: "最大值" },
                                { type: "min", name: "最小值" }
                            ]
                        },
                        markLine: {
                            data: [
                                { type: "average", name: "平均值" }
                            ]
                        }
                    },
                    {
                        name: '举报评分',
                        type: 'line',
                        data: past_1m_accusation_rating.value[region.value],
                        yAxisIndex: 1,
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        markPoint: {
                            data: [
                                { type: "max", name: "最大值" },
                                { type: "min", name: "最小值" }
                            ]
                        },
                        markLine: {
                            data: [
                                { type: "average", name: "平均值" }
                            ]
                        }
                    }
                ],
                legend: {
                    // data: ['投诉', '投诉复议', '举报', '举报复议', '投诉评分', '举报评分'],
                    data: ['投诉', '举报'],
                }
            });
            past_1y_chart.value.setOption({
                xAxis: {
                    data: past_1y_x.value
                },
                series: [
                    {
                        name: '投诉',
                        type: 'bar',
                        data: past_1y_complaint.value[region.value],
                        stack: 'complaint',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        name: '投诉复议',
                        type: 'bar',
                        data: past_1y_complaint_repeat.value[region.value],
                        stack: 'complaint',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        name: '举报',
                        type: 'bar',
                        data: past_1y_accusation.value[region.value],
                        stack: 'accusation',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        name: '举报复议',
                        type: 'bar',
                        data: past_1y_accusation_repeat.value[region.value],
                        stack: 'accusation',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    },
                    {
                        name: '投诉评分',
                        type: 'line',
                        data: past_1y_complaint_rating.value[region.value],
                        yAxisIndex: 1,
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        markPoint: {
                            data: [
                                { type: "max", name: "最大值" },
                                { type: "min", name: "最小值" }
                            ]
                        },
                        markLine: {
                            data: [
                                { type: "average", name: "平均值" }
                            ]
                        }
                    },
                    {
                        name: '举报评分',
                        type: 'line',
                        data: past_1y_accusation_rating.value[region.value],
                        yAxisIndex: 1,
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        markPoint: {
                            data: [
                                { type: "max", name: "最大值" },
                                { type: "min", name: "最小值" }
                            ]
                        },
                        markLine: {
                            data: [
                                { type: "average", name: "平均值" }
                            ]
                        }
                    }
                ],
                legend: {
                    // data: ['投诉', '投诉复议', '举报', '举报复议', '投诉评分', '举报评分'],
                    data: ['投诉', '举报'],
                }
            });
        }

        function get_static() {
            axios
                .post("/api/server/getStatics")
                .then((res) => {
                    console.log(res.data);
                    if (res.data.success) {
                        console.log("success");
                        waiting_accusation.value = res.data.data.waiting_accusation;
                        waiting_complaint.value = res.data.data.waiting_complaint;
                        past_1w_accusation.value = res.data.data.past_1w_accusation;
                        past_1w_accusation_repeat.value = res.data.data.past_1w_accusation_repeat;
                        past_1w_accusation_rating.value = res.data.data.past_1w_accusation_rating;
                        past_1w_complaint.value = res.data.data.past_1w_compliant;
                        past_1w_complaint_repeat.value = res.data.data.past_1w_compliant_repeat;
                        past_1w_complaint_rating.value = res.data.data.past_1w_compliant_rating;
                        past_1w_x.value = res.data.data.past_1w_days;
                        past_1m_accusation.value = res.data.data.past_1m_accusation;
                        past_1m_accusation_repeat.value = res.data.data.past_1m_accusation_repeat;
                        past_1m_accusation_rating.value = res.data.data.past_1m_accusation_rating;
                        past_1m_complaint.value = res.data.data.past_1m_compliant;
                        past_1m_complaint_repeat.value = res.data.data.past_1m_compliant_repeat;
                        past_1m_complaint_rating.value = res.data.data.past_1m_compliant_rating;
                        past_1m_x.value = res.data.data.past_1m_days;
                        past_1y_accusation.value = res.data.data.past_1y_accusation;
                        past_1y_accusation_repeat.value = res.data.data.past_1y_accusation_repeat;
                        past_1y_accusation_rating.value = res.data.data.past_1y_accusation_rating;
                        past_1y_complaint.value = res.data.data.past_1y_complaint;
                        past_1y_complaint_repeat.value = res.data.data.past_1y_complaint_repeat;
                        past_1y_complaint_rating.value = res.data.data.past_1y_complaint_rating;
                        past_1y_x.value = res.data.data.past_1y_months;
                        for (let i = 0; i < past_1w_x.value.length; i++) {
                            past_1w_x.value[i] = moment(past_1w_x.value[i]).format('YYYY-MM-DD');
                        }
                        for (let i = 0; i < past_1m_x.value.length; i++) {
                            past_1m_x.value[i] = moment(past_1m_x.value[i]).format('YYYY-MM-DD');
                        }
                        for (let i = 0; i < past_1y_x.value.length; i++) {
                            past_1y_x.value[i] = moment(past_1y_x.value[i]).format('YYYY-MM');
                        }
                        if (store.state.userInfo.userInfo.permission == 1) {
                            region.value = store.state.userInfo.userInfo.region;
                            draw_graph();
                        } else {
                            axios
                            .post("/api/server/getCategoryMap")
                            .then((res) => {
                                // console.log(res.data);
                                if (res.data.success) {
                                    region.value = Object.keys(res.data.data)[0];
                                    draw_graph();
                                }
                            });
                        }
                    }
                });
        }

        onMounted(() => {
            store.dispatch({ type: 'setSelectedKeys', selectedKeys: ['Home'] });
            store.dispatch({ type: 'setOpenKeys', openKeys: [] });
            get_static();
        });

        return {
            waiting_complaint,
            waiting_accusation,
            past_1w_complaint,
            past_1w_complaint_repeat,
            past_1w_complaint_rating,
            past_1w_accusation,
            past_1w_accusation_repeat,
            past_1w_accusation_rating,
            past_1w_x,
            past_1m_complaint,
            past_1m_complaint_repeat,
            past_1m_complaint_rating,
            past_1m_accusation,
            past_1m_accusation_repeat,
            past_1m_accusation_rating,
            past_1m_x,
            past_1y_complaint,
            past_1y_complaint_repeat,
            past_1y_complaint_rating,
            past_1y_accusation,
            past_1y_accusation_repeat,
            past_1y_accusation_rating,
            past_1y_x,
            past_1w_chart_container,
            past_1m_chart_container,
            past_1y_chart_container,
            region,
            category,
            draw_graph
        }
    }
}
</script>

<style scoped>
#dashboard {
    width: 100%;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 500px;
  background: #2f66cb;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
</style>
